<template>
    <div class="accordion bg-white rounded-2xl lg:rounded-3xl">
        <div
            :class="{'accordion__opener--opened': opened}"
            class="accordion__opener relative cursor-pointer py-4 pl-4 pr-14 lg:py-9 lg:pl-10 lg:pr-42"
            @click="toggleAccordion"
        >
            <slot name="opener"></slot>
        </div>
        <div ref="content" class="accordion__content h-0 overflow-hidden">
            <div ref="inner" class="accordion__inner pb-4 pl-4 pr-14 lg:pb-9 lg:pl-10 lg:pr-42">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { gsap } from '@lahautesociete/gsap';

    export default {
        data() {
            return {
                opened: false
            }
        },

        methods: {
            toggleAccordion() {
                this.opened = !this.opened;

                gsap.to(this.$refs.content, {
                    height: (this.opened) ? this.$refs.inner.clientHeight : 0,
                    duration: 0.6,
                    ease: 'power2.out'
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.accordion {
    &__opener {
        &::after {
            @apply absolute top-1/2 -translate-y-1/2 right-6 lg:right-12 bg-center bg-contain bg-no-repeat w-4 h-4 lg:w-7 lg:h-7 transition-transform duration-200;
            content: '';
            background-image: url('~@/static/images/chevron-down-black.svg');
        }

        &--opened {
            &::after {
                @apply rotate-180;
            }
        }
    }
}
</style>
