<template>
    <div class="text-and-notifications-card bg-green-dark rounded-3xl text-white text-center px-4 pt-10 lg:px-10 lg:pt-12">
        <h3><strong>{{ card.heading }}</strong></h3>
        <p class="lead mt-2 mb-6">{{ card.text }}</p>

       <animate-in
           v-for="(notification, key) in card.notifications"
           :key="key"
           :init-Y="20"
           :init-opacity="0"
           :delay="0.75 + key*0.15"
           :duration="0.3"
       >
           <img
               :src="notification.url"
               :alt="notification.alt"
               :class="{'mt-3': key > 0}"
           >
       </animate-in>
    </div>
</template>

<script>
import AnimateIn from "@/app/animations/AnimateIn";

export default {
    name: "TextAndNotificationCard",

    props: {
        card: {
            type: Object,
            required: true,
        },
    },

    components: {
        AnimateIn,
    },
}
</script>

<style scoped>

</style>
