<template>
    <intersector :threshold="[0.2]" @enter="enterHandler">
        <div class="hero h-screen bg-blue flex items-center">
            <div class="grid-container">
                <div ref="headingContainer" class="hero__heading-container relative">
                    <h1 ref="heading" class="hero__heading block relative z-10 display text-green-light text-center" v-html="heading" />
                </div>
            </div>
        </div>
    </intersector>
</template>

<script>
import { gsap } from "@lahautesociete/gsap";
import { SplitText } from "@lahautesociete/gsap/SplitText";
import Intersector from "@/app/animations/Intersector";

export default {
    name: "HeroAnimation",

    props: {
        heading: {
            type: String,
            required: true,
        },
    },

    components: {
        Intersector
    },

    data() {
        return {
            splittedText: null
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.splittedText = new SplitText(this.$refs.heading, {type: 'lines'});

            gsap.set(this.$refs.headingContainer, {
                opacity: 0,
            });

            gsap.set(this.splittedText.lines, {
                opacity: 0,
                y: 20,
                rotate: 1,
            });
        })
    },

    methods: {
        enterHandler() {
            gsap.to(this.$refs.headingContainer, {
                opacity: 1,
                ease: 'power3.out',
                duration: 0.5,
            });

            gsap.to(this.splittedText.lines, {
                opacity: 1,
                y: 0,
                rotate: 0,
                ease: 'power3.out',
                delay: 0.3,
                stagger: 0.15,
                duration: 1,
            });
        },
    }
}
</script>

<style scoped lang="scss">
.hero {
    :deep(.hero__heading) {
        .hero__ornament {
            @apply inline-flex font-bold font-clash-grotesk;

            &--pink {
                @apply text-pink border border-pink rounded-full px-8;
            }

            &--green {
                @apply bg-green-dark rounded-lg px-4;
            }
        }
    }

    &__heading-container {
        &::before {
            @apply absolute left-0 bg-center bg-cover;
            content: '';
            top: -2.1875rem;
            width: 9rem;
            height: 6.375rem;
            background-image: url('~@/static/images/hero-rectangle.svg');

            @screen lg {
                top: -3.625rem;
                width: 15.0625rem;
                height: 10.8125rem;
            }
        }

        &::after {
            @apply absolute top-1/2 bg-center bg-contain bg-no-repeat;
            content: '';
            left: 0;
            width: 13.125rem;
            height: 4.6875rem;
            background-image: url('~@/static/images/hero-ellipse.svg');

            @screen lg {
                left: 13.875rem;
                width: 22.375rem;
                height: 8rem;
            }
        }
    }
}
</style>
