<template>
    <div class="header-nav relative">
        <header :class="isAbsolute ? 'absolute inset-x-0 top-0': 'bg-blue'" class="w-full py-6 z-50">
            <nav class="grid-container flex items-center justify-between" :aria-label="ariaLabels.menu">
                <a :href="siteUrl" :aria-label="ariaLabels.homeLink">
                    <img
                        :src="logoUrl"
                        alt="Bankstage"
                        class="header-nav__logo"
                    >
                </a>

                <div class="hidden lg:flex">
                    <a
                        v-for="(link, key) in linkList"
                        :key="key"
                        :href="link.url"
                        :target="link.target"
                        class="btn-nav"
                    >{{ link.label }}</a>

                    <a
                        :href="lastLink.url"
                        :target="lastLink.target"
                        class="btn-nav ml-10"
                    >{{ lastLink.label }}</a>
                </div>

                <button
                    :aria-label="menuButtonAriaLabel"
                    :class="isMenuOpened ? 'header-nav__menu-btn--cross': 'header-nav__menu-btn--burger'"
                    class="header-nav__menu-btn lg:hidden w-10 h-10 bg-center bg-cover"
                    @click="toggleMenu"
                ></button>
            </nav>
        </header>

        <transition name="fade">
            <header-nav-menu-mobile v-if="isMenuOpened" :link-list="linkList" :last-link="lastLink"></header-nav-menu-mobile>
        </transition>
    </div>
</template>

<script>
import HeaderNavMenuMobile from "@/app/components/HeaderNavMenuMobile";

export default {
    name: "HeaderNav",

    props: {
        siteUrl: {
            type: String,
            required: true,
        },

        logoUrl: {
            type: String,
            required: true,
        },

        linkList: {
            type: Array,
            required: true,
        },

        lastLink: {
            type: Object,
            required: true,
        },

        isAbsolute: {
            type: Boolean,
            default: false,
        },

        ariaLabels: {
            type: Object,
            required: true,
        },
    },

    components: {
        HeaderNavMenuMobile,
    },

    data() {
        return {
            isMenuOpened: false,
        }
    },

    computed: {
        menuButtonAriaLabel() {
            return this.isMenuOpened ? this.ariaLabels.closeMenu: this.ariaLabels.openMenu;
        },
    },

    methods: {
        toggleMenu() {
            this.isMenuOpened = !this.isMenuOpened;

            document.body.style.overflow = this.isMenuOpened ? 'hidden': 'auto';
        }
    }
}
</script>

<style scoped lang="scss">
    .header-nav {
        &__logo {
            width: 7.8125rem;
            height: 1.5625rem;
        }

        &__menu-btn {
            &--burger {
                background-image: url('~@/static/images/burger-menu.svg');
            }

            &--cross {
                background-image: url('~@/static/images/cross-white.svg');
            }
        }

        .fade-enter-active,
        .fade-leave-active {
            transition: opacity 0.35s ease;
        }

        .fade-enter-from,
        .fade-leave-to {
            opacity: 0;
        }
    }
</style>
