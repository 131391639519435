<template>
    <div class="text-and-image-card bg-blue rounded-3xl text-white text-center px-4 py-10 lg:px-10 lg:py-12">
        <h3><strong>{{ card.heading }}</strong></h3>
        <p class="lead mt-2">{{ card.text }}</p>
        <p class="h3 mt-4">{{ card.price }}</p>

        <img :src="card.image.url" :alt="card.image.alt" class="mt-8 transform -rotate-4">
    </div>
</template>

<script>
export default {
    name: "TextAndImageCard",

    props: {
        card: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped>

</style>
