<template>
    <nav class="anchors-menu flex flex-col bg-pink rounded-3xl p-8 mb-6">
        <a
            v-for="(item, key) in items"
            :key="key"
            :href="item.identifier"
            :class="{'mt-4': key > 0}"
            class="lead text-blue"
        >
            <strong v-if="item.identifier === `#${currentAnchor}`">
                {{ item.heading }}
            </strong>
            <template v-else>
                {{ item.heading }}
            </template>
        </a>
    </nav>
</template>

<script>
export default {
    name: "AnchorsMenu",

    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            currentAnchor: null,
        }
    },

    mounted() {
        this.emitter.on('updateAnchorsMenu', this.updateAnchorsMenuHandler)
    },

    methods: {
        updateAnchorsMenuHandler(identifier) {
            this.currentAnchor = identifier;
        },
    },
}
</script>
