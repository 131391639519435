<template>
    <div class="banking-experience-roulette relative hidden lg:block">
        <button class="banking-experience-roulette__button absolute -bottom-8 right-1/2 h3 text-blue" @click="toggleRoulette">
            <span ref="buttonBackground" class="banking-experience-roulette__button-background"></span>
            <strong class="block transform -rotate-8">{{ ctaText }}</strong>
        </button>

        <transition name="fade" @enter="stepsFadeIn" @leave="stepsFadeOut">
            <div
                v-if="isRouletteOpened"
                class="banking-experience-roulette__steps fixed inset-0 w-screen h-screen z-30"
            >
                <button
                    :class="`banking-experience-roulette__close-button--${closeButtonColor}`"
                    class="banking-experience-roulette__close-button w-12 h-12 bg-cover bg-center absolute top-12 right-12"
                    :aria-label="ariaLabelClose"
                    @click="toggleRoulette"
                ></button>

                <banking-experience-roulette-step :step="currentStep"></banking-experience-roulette-step>
            </div>
        </transition>
    </div>
</template>

<script>
import { gsap } from "@lahautesociete/gsap";
import BankingExperienceRouletteStep from "@/app/components/BankingExperienceRouletteStep";

export default {
    name: "BankingExperienceRoulette",

    props: {
        ctaText: {
            type: String,
            required: true,
        },
        steps: {
            type: Array,
            required: true,
        },
        ariaLabelClose: {
            type: String,
            required: true,
        },
    },

    components: {
        BankingExperienceRouletteStep,
    },

    data() {
        return {
            isRouletteOpened: false,
            currentStepId: null,
        }
    },

    computed: {
        currentStep() {
            return this.steps[this.currentStepId];
        },

        closeButtonColor() {
            return this.currentStep.backgroundColor === 'purple' ? 'green-light' : 'blue';
        },
    },

    methods: {
        toggleRoulette() {
            this.isRouletteOpened = !this.isRouletteOpened;

            if (this.isRouletteOpened) {
                this.currentStepId = this.getRandomStepId();
            }

            gsap.to(this.$refs.buttonBackground, {
                scale: this.isRouletteOpened ? 8: 1,
                duration: 0.35,
                ease: 'power2.out',
            });

            document.body.style.overflow = this.isRouletteOpened ? 'hidden': 'auto';
        },

        stepsFadeIn(element, done) {
            gsap.fromTo(element, {
                opacity: 0,
            }, {
                opacity: 1,
                duration: 0.25,
                delay: 0.1,
            });

            done();
        },

        stepsFadeOut(element, done) {
            gsap.to(element, {
                opacity: 0,
                duration: 0.25,
                onComplete: done
            });
        },

        getRandomStepId() {
            let nextStepId = 0;
            do {
                nextStepId = Math.floor(Math.random() * (this.steps.length));
            } while (nextStepId === this.currentStepId);

            return nextStepId;
        },
    },
}
</script>

<style scoped lang="scss">
    .banking-experience-roulette {
        &__button {
            @apply p-6;
            width: 16rem;
            height: 9.375rem;

            &::before {
                @apply absolute bg-center bg-cover;
                content: '';
                width: 19.5rem;
                height: 19.5rem;
                margin-top: -6.375rem;
                margin-left: -9.75rem;
                background-image: url('~@/static/images/banking-experience-roulette.svg');

                animation: rotating 20s linear infinite;

                @keyframes rotating {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        &__button-background {
            @apply absolute inset-0 w-full h-full bg-cover bg-contain bg-no-repeat;
            background-image: url('~@/static/images/ellipse-roulette.svg');
        }

        &__close-button {
            &--blue {
                background-image: url('~@/static/images/cross-blue.svg');
            }

            &--green-light {
                background-image: url('~@/static/images/cross-green-light.svg');
            }
        }
    }
</style>
