<template>
    <intersector :threshold="[1]" :trigger-once="false" @enter="enterHandler">
        <div :id="identifier"></div>
    </intersector>
</template>

<script>
import Intersector from "@/app/animations/Intersector";

export default {
    name: "Anchor",

    components: {
        Intersector,
    },

    props: {
        identifier: {
            type: String,
            required: true,
        },
    },

    methods: {
        enterHandler() {
            this.emitter.emit('updateAnchorsMenu', this.identifier);
        },
    },
}
</script>
