<template>
    <nav class="header-nav-menu-mobile lg:hidden fixed inset-0 w-screen h-screen bg-blue z-40 overflow-auto">
        <nav class="flex flex-col items-center justify-center min-h-screen py-20">
            <a
                ref="links"
                v-for="(link, key) in linkList"
                :key="key"
                :href="link.url"
                :target="link.target"
                class="header-nav-menu-mobile__link btn-nav mt-5 opacity-0 translate-x-20"
            >{{ link.label }}</a>

            <a
                v-if="lastLink"
                :href="lastLink.url"
                :target="lastLink.target"
                class="header-nav-menu-mobile__link btn-nav mt-10 opacity-0 translate-x-20"
            >{{ lastLink.label }}</a>
        </nav>
    </nav>
</template>

<script>
import { gsap } from "@lahautesociete/gsap";

export default {
    name: "HeaderNavMenuMobile",

    props: {
        linkList: {
            type: Array,
            required:true,
        },
        lastLink: {
            type: Object,
            default: null,
        },
    },

    mounted() {
        const links = document.querySelectorAll('.header-nav-menu-mobile__link');

        gsap.to(links, {
            x: 0,
            opacity: 1,
            ease: 'power2.out',
            stagger: 0.2,
            force3D: true,
            delay: 0.1,
            duration: 0.5,
        });
    },
}
</script>

<style scoped>

</style>
