<template>
    <div class="product-carousel flex flex-col lg:flex-row rounded-4xl lg:rounded-xxl overflow-hidden" id="product-carousel">
        <div class="w-full lg:w-1/2 bg-pink text-blue py-12 px-6 lg:px-30 lg:py-22">
            <h2 class="h1">
                {{ heading }}
            </h2>
        </div>
        <div class="product-carousel__carousel-container relative w-full lg:w-1/2 bg-blue py-8 lg:py-0 lg:px-16 overflow-hidden flex flex-col justify-center">
            <carousel
                ref="carousel"
                :settings="settings"
                v-model="currentItemId"
                class="relative z-10"
            >
                <slide v-for="(item, key) in items" :key="`slide-${key}`">
                    <div class="px-6">
                        <article class="product-carousel__slide text-blue bg-no-repeat bg-center bg-contain px-10 py-18 lg:px-22 lg:py-33">
                            <p class="h3">
                                <strong>
                                    {{ item.text }}
                                </strong>
                            </p>
                        </article>
                    </div>
                </slide>
            </carousel>

            <div class="hidden lg:flex items-center absolute bottom-4 left-1/2 -translate-x-1/2">
                <button @click="prev()" class="arrow-btn arrow-btn--left-white mr-3" :aria-label="ariaLabels.previous"></button>
                <ul class="flex items-center">
                    <li v-for="(item, key) in items" :key="`folio-${key}`" class="flex mx-1">
                        <button
                            :class="currentItemId === key ? 'bg-green-light': 'bg-white/50'"
                            class="w-2 h-2 rounded-full"
                            @click="currentItemId = key"
                        ></button>
                    </li>
                </ul>
                <button @click="next()" class="arrow-btn arrow-btn--right-white ml-3" :aria-label="ariaLabels.next"></button>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
    name: "ProductCarousel",

    props: {
        heading: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        ariaLabels: {
            type: Object,
            required: true,
        },
    },

    components: {
        Carousel,
        Slide,
    },

    data() {
        return {
            currentItemId: 0,
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            intervalId: null,
        }
    },

    mounted() {
        this.intervalId = setInterval(() => {
            this.next(false);
        }, 4000);
    },

    unmounted() {
        clearInterval(this.intervalId);
    },

    methods: {
        prev() {
            clearInterval(this.intervalId);

            if (this.currentItemId === 0) {
                this.currentItemId = this.items.length - 1;
                return;
            }

            this.$refs.carousel.prev();
        },

        next(clearItvl = true) {
            if (clearItvl) {
                clearInterval(this.intervalId);
            }

            if (this.currentItemId === this.items.length - 1) {
                this.currentItemId = 0;
                return;
            }

            this.$refs.carousel.next();
        },
    }
}
</script>

<style scoped lang="scss">
.product-carousel {
    &__carousel-container {
        &::after {
            @apply absolute top-1/2 inset-x-0 w-full h-px bg-white;
            content: '';
        }
    }

    :deep(.carousel) {
        .carousel__viewport {
            overflow: visible;
        }

        .carousel__slide {
            &:nth-child(3n+1) {
                .product-carousel__slide {
                    background-image: url('~@/static/images/losange-mobile.svg');

                    @screen lg {
                        background-image: url('~@/static/images/losange.svg');
                    }
                }
            }
            &:nth-child(3n+2) {
                .product-carousel__slide {
                    background-image: url('~@/static/images/ellipse-mobile.svg');

                    @screen lg {
                        background-image: url('~@/static/images/ellipse.svg');
                    }
                }
            }
            &:nth-child(3n+3) {
                .product-carousel__slide {
                    background-image: url('~@/static/images/rectangle-mobile.svg');

                    @screen lg {
                        background-image: url('~@/static/images/rectangle.svg');
                    }
                }
            }
        }
    }
}
</style>
