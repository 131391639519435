<template>
    <div class="two-cards my-12 lg:my-22 overflow-hidden">
        <div class="grid-container">
            <div class="grid-row justify-center">
                <div class="grid-col w-full lg:w-2/3 mb-9 lg:mb-10">
                    <slot></slot>
                </div>

                <div
                    v-for="(card, key) in cards"
                    :key="key"
                    class="grid-col w-full lg:w-5/12"
                    :class="{'mt-4 lg:mt-0': key > 0}"
                >
                    <animate-in :init-y="50" :delay="key*0.3" :init-opacity="0" :duration="0.35" class="h-full">
                        <component
                            :is="card.type"
                            :card="card"
                            class="h-full"
                        ></component>
                    </animate-in>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AnimateIn from "@/app/animations/AnimateIn";
import TextAndImageCard from "@/app/components/TextAndImageCard";
import TextAndNotificationsCard from "@/app/components/TextAndNotificationsCard";

export default {
    name: "TwoCards",

    props: {
        cards: {
            type: Array,
            required: true,
        },
    },

    components: {
        TextAndImageCard,
        TextAndNotificationsCard,
        AnimateIn,
    },

    methods: {

    }
}
</script>

<style scoped lang="scss">

</style>
