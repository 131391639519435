import "@fontsource/inter";
import "./assets/styles/main.scss";

import {createApp} from 'vue'
import CsrfInput from "@/app/components/CsrfInput";
import TwoCards from "@/app/components/TwoCards";
import HeaderNav from "@/app/components/HeaderNav";
import Accordion from "@/app/components/Accordion";
import ProductCarousel from "@/app/components/ProductCarousel";
import Anchor from "@/app/components/Anchor";
import AnchorsMenu from "@/app/components/AnchorsMenu";
import BankingExperienceRoulette from "@/app/components/BankingExperienceRoulette";
import AnimateIn from "@/app/animations/AnimateIn";
import Hero from "@/app/components/Hero";

import mitt from 'mitt';

const emitter = mitt();

const app = createApp({
    name: 'App',
    components: {
        CsrfInput,
        TwoCards,
        HeaderNav,
        Accordion,
        ProductCarousel,
        Anchor,
        AnchorsMenu,
        BankingExperienceRoulette,
        AnimateIn,
        Hero,
    },
});
app.config.globalProperties.emitter = emitter;
app.mount('#app');
