<template>
    <div :class="`bg-${step.backgroundColor}`" class="banking-experience-roulette-step h-screen w-screen overflow-auto">
        <div :class="textColor" class="grid-container py-22">
            <div class="grid-row justify-center items-end">
                <div ref="text" class="grid-col w-2/3">
                    <div class="h1" v-html="step.text"></div>
                </div>
                <div ref="link" class="grid-col w-1/6">
                    <a
                        v-if="step.link"
                        :href="step.link.url"
                        :target="step.link.target"
                        class="btn-text btn-text--medium text-blue"
                    >
                        {{ step.link.label }}
                    </a>
                </div>
            </div>
            <div class="grid-row justify-center mt-8">
                <div ref="picto" class="grid-col w-5/6">
                    <img v-if="step.picto" :src="step.picto.url" :alt="step.picto.alt">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "@lahautesociete/gsap";

export default {
    name: "BankingExperienceRouletteStep",

    props: {
        step: {
            type: Object,
            required: true,
        },
    },

    computed: {
        textColor() {
            return this.step.backgroundColor === 'purple' ? 'text-green-light' : 'text-blue';
        },
    },

    mounted() {
        gsap.from(this.$refs.text, {
            opacity: 0,
            x: 100,
            delay: 0.4,
            duration: 0.5,
            ease: 'power3.out'
        });

        gsap.from(this.$refs.picto, {
            opacity: 0,
            x: 100,
            delay: 0.55,
            duration: 0.5,
            ease: 'power3.out'
        });

        gsap.from(this.$refs.link, {
            opacity: 0,
            x: 100,
            delay: 0.7,
            duration: 0.5,
            ease: 'power3.out'
        });
    },
}
</script>
